// const BaseUrl='https://cargo.saranawarehouse.co.id/service/';
const BaseUrl='/service/';
const Title='Passenger Risk Management';
const BgColor='#059742';
const withDomestic=true;
const defaultOriginDom={_id:'60a3d868045fd93f38efc29a', code:"CGK", kota:'Jakarta'};
const backgroundGradient="radial-gradient(circle, rgba(250,250,250,1) 0%, rgba(58,164,0,1) 62%)";
const NoVessel = true;

export default {
    BaseUrl,
    Title,
    BgColor,
    withDomestic,
    defaultOriginDom,
    backgroundGradient,
    NoVessel
}
