/* eslint-disable react-hooks/exhaustive-deps */
// import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import {DatePicker, Button, Form} from 'antd';

import React, { useEffect, useMemo, useState } from 'react';
import MyPage from '../Page';
import moment from 'moment';

import TableSection from './component/table';
// import { Button } from '@material-ui/core';
import FilterData from './component/filter';
import {FileSearchOutlined} from '@ant-design/icons'

export default ({url, title, repType, FetchComponent, printHeaderReport, printFooterReport, onDataChange,
    onFilterChange, onDateChange}) => {
    const [timestamp, setTimestamp]=useState(moment().unix());
    const [startDate, setStartDate]=useState(moment());
    const [endDate, setEndDate]=useState(moment().add(1, 'day'));    
    const [selectedValues, setSelectedValues]=useState([]);    
    const [filterLists, setFilterLists]=useState([]);
    const curUrl=useMemo(()=>{
        return `${startDate.format('DD-MMM-YYYY')}/${endDate.format('DD-MMM-YYYY')}`;
    },[startDate, endDate]);
    const [uri, setUri]=useState(curUrl);

    const maxEndDate=useMemo(()=>{
        const nextWeek=moment(startDate).add(7,'day');
        return (nextWeek.unix() > moment().add(1,'day').unix())?moment().add(1, 'day'):nextWeek;
    }, [startDate]);

    useEffect(()=>{
        if(endDate.startOf('date').unix()>maxEndDate.startOf('date').unix()){
            setEndDate(maxEndDate);
        }
    }, [maxEndDate])

    useEffect(()=>{
        onFilterChange && typeof onFilterChange ==='function' && onFilterChange(selectedValues);
    }, [selectedValues])

    useEffect(() => {
        onDateChange && typeof onDateChange ==='function' && onDateChange(startDate, endDate);
    }, [startDate, endDate])

    const DailyBtn=()=>(
        <Form layout='inline'>
            <Form.Item label="Start Date">
                <DatePicker 
                    value={startDate}
                    format='DD-MMM-YYYY'
                    onChange={date => {
                        setStartDate(date);
                    }}
                    size='large'  
                    allowClear={false}
                    style={{marginInline: 5}}                      
                    disabledDate={curr=>moment().startOf('date').unix()<curr.startOf('date').unix()}
                />   
                
            </Form.Item>
            <Form.Item label="End Date">
                <DatePicker 
                    value={endDate}
                    format='DD-MMM-YYYY'
                    onChange={date => {
                        setEndDate(date);
                    }}
                    allowClear={false}
                    size='large'  
                    style={{marginInline: 5}}                      
                    disabledDate={curr=>{
                        const currUnix=curr.startOf('date').unix();
                        const startUnix=startDate.startOf('date').unix()>=currUnix;
                        const tomorrowOk=moment().add(1, 'days').startOf('date').unix()<currUnix;
                        const maxDate=maxEndDate.startOf('date').unix()<currUnix;
                        return !!maxDate || !!tomorrowOk || !!startUnix;
                    }}
                />          
            </Form.Item>
            <Form.Item>
                <Button
                    type='default'
                    color="primary"
                    icon={<FileSearchOutlined />}
                    onClick={()=>{
                        setUri(curUrl);
                        setTimestamp(moment().unix());
                    }}
                >
                    Search
                </Button>
            </Form.Item>
        </Form>
    );
    return (
        <MyPage title={title}>
            <div style={{display:'flex', flexDirection:'column', paddingBlock:30}}>
                <div style={{display:'flex', flexDirection:'row', marginTop:5, marginBottom:16}}>
                    <DailyBtn />                  
                </div>
                <FilterData 
                    lists={filterLists} 
                    idReport={`${url}${repType}`}
                    onFilterValueChange={fval=>{
                        setSelectedValues(fval);
                    }}
                />
                <TableSection 
                    date={uri} 
                    baseUrl={url} 
                    FetchComponent={FetchComponent}
                    repType={repType} 
                    timestamp={timestamp}
                    selectedValue={selectedValues}
                    setFilterLists={setFilterLists}
                    printHeader={printHeaderReport}
                    printFooter={printFooterReport}
                    onDataChange={onDataChange}
                />
            </div>
        </MyPage>
    )
}