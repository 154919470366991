/* eslint-disable react-hooks/exhaustive-deps */
// import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import {DatePicker, Form} from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import MyPage from '../Page';
import moment from 'moment';
import TableSection from './component/table';
import FilterData from './component/filter';

export default ({url, title, repType, FetchComponent, printHeaderReport, printFooterReport, onDataChange,
    onDateChange, onFilterChange}) => {
    const [timestamp, setTimestamp]=useState(moment().unix());
    const [startDate, setStartDate]=useState(moment().subtract(1,'day'));
    const [selectedValues, setSelectedValues]=useState([]);    
    // const [values, setValues]=useState([]);
    const [filterLists, setFilterLists]=useState([]);
    const curUrl=useMemo(()=>{
        return `${startDate.format('MMM-YYYY')}`;
    },[startDate]);
    const [uri, setUri]=useState(curUrl);

    useEffect(()=>{
        onFilterChange && typeof onFilterChange ==='function' && onFilterChange(selectedValues);
    }, [selectedValues])

    useEffect(() => {
        onDateChange && typeof onDateChange ==='function' && onDateChange(startDate);
    }, [startDate])

    const DailyBtn=()=>(
        <Form layout='inline'>
            <Form.Item label="Month">
                <DatePicker.MonthPicker                     
                    value={startDate}
                    format='MMM-YYYY'
                    allowClear={false}
                    onChange={adate => {
                        setStartDate(adate);
                        setUri(adate.format('MMM-YYYY'));
                        setTimestamp(moment().unix());
                    }}
                    size='small'  
                    style={{marginInline: 5}}                      
                    disabledDate={current => moment().add(1, 'day').diff(current, 'days')<0}
                />
            </Form.Item>
        </Form>
    );
    return (
        <MyPage title={title}>
            <div style={{display:'flex', flexDirection:'column', paddingBlock:30}}>
                <div style={{display:'flex', flexDirection:'row', marginBlock:5}}>
                    <DailyBtn />                  
                </div>
                <FilterData 
                    lists={filterLists} 
                    idReport={`${url}${repType}`}
                    onFilterValueChange={fval=>{
                        setSelectedValues(fval);
                    }}
                />
                <TableSection 
                    date={uri} 
                    baseUrl={url} 
                    FetchComponent={FetchComponent}
                    printFooter={printFooterReport}
                    printHeader={printHeaderReport}
                    onDataChange={onDataChange}
                    repType={repType} 
                    timestamp={timestamp}
                    selectedValue={selectedValues}
                    setFilterLists={setFilterLists}
                />
            </div>
        </MyPage>
    )
}